import { defineStore } from "pinia";
import type { RealtimeChannel } from "@supabase/realtime-js";
import type { Application } from "pixi.js";
import type { ShallowRef } from "vue";
import type { ReactionState } from "~/components/canvas/CanvasReactions.vue";
import type { IStarSystem } from "~/components/canvas/newStars/types";
import type StarStructure from "~/components/canvas/newStars/starStructure";

export type Message = {
  content: string;
  userName: string;
  userImage: string;
};

export type ReactionInstance = {
  type: string;
  size: number;
  rotation: number;
  speed: number;
  startX: number;
  offsetX: number;
  imageIndex: number;
  originalSize: number;
};

export type ReactionConfig = {
  colors: string[];
  svgs: string[];
  movement: ( reaction: ReactionState, time: number )=> void;
};

const MAX_REACTIONS = 50;

// Configuration object
export const reactionConfig: Record<string, ReactionConfig> = {
  heart: {
    colors: ["#FF69B4", "#FF1493", "#DB7093"],
    svgs: [
      `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>
  `],
    movement: ( reaction: ReactionState, time: number ) => {
      reaction.x += Math.sin( time + reaction.offsetX ) * 0.5; // Sway effect
    },
  },
  note: {
    colors: ["#6493d7", "#a191da", "#927adc"],
    svgs: [
      `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"/></svg>
    `,
      `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M384 32c0-9.9-4.5-19.2-12.3-25.2S353.8-1.4 344.2 1l-128 32C202 36.5 192 49.3 192 64v64V359.7c-14.5-4.9-30.8-7.7-48-7.7c-61.9 0-112 35.8-112 80s50.1 80 112 80s112-35.8 112-80V153L359.8 127C374 123.5 384 110.7 384 96V32z"/></svg>
    `,
    ],
    movement: ( reaction: ReactionInstance, time: number ) => {
      reaction.size = reaction.originalSize + Math.sin( time * 10 ) * 1.5; // Thump effect
    },
  },
  star: {
    colors: [
      "#ffeedd",
      "#eeffdd",
      "#ddeeff",
      "#ffddbb",
      "#ddffcc",
      "#ccddff",
      "#ffdddd",
      "#e0e0ff",
      "#ffe0cc",
    ],
    svgs: [
      `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.153 5.408C10.42 3.136 11.053 2 12 2c.947 0 1.58 1.136 2.847 3.408l.328.588c.36.646.54.969.82 1.182c.28.213.63.292 1.33.45l.636.144c2.46.557 3.689.835 3.982 1.776c.292.94-.546 1.921-2.223 3.882l-.434.507c-.476.557-.715.836-.822 1.18c-.107.345-.071.717.001 1.46l.066.677c.253 2.617.38 3.925-.386 4.506c-.766.582-1.918.051-4.22-1.009l-.597-.274c-.654-.302-.981-.452-1.328-.452c-.347 0-.674.15-1.328.452l-.596.274c-2.303 1.06-3.455 1.59-4.22 1.01c-.767-.582-.64-1.89-.387-4.507l.066-.676c.072-.744.108-1.116 0-1.46c-.106-.345-.345-.624-.821-1.18l-.434-.508c-1.677-1.96-2.515-2.941-2.223-3.882c.293-.941 1.523-1.22 3.983-1.776l.636-.144c.699-.158 1.048-.237 1.329-.45c.28-.213.46-.536.82-1.182z"/></svg>
      `,
      `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path fill-rule="evenodd" d="M7.935.655c-.318-.873-1.552-.873-1.87 0L4.622 4.622L.655 6.065c-.873.318-.873 1.552 0 1.87l3.967 1.443l1.443 3.967c.318.873 1.552.873 1.87 0l1.443-3.967l3.967-1.443c.873-.318.873-1.552 0-1.87L9.378 4.622z" clip-rule="evenodd"/></svg>
      `,
    ],
    movement: ( reaction: ReactionInstance ) => {
      const reverse = ( reaction.imageIndex == 1 && reaction.originalSize > 0.5 ) || ( reaction.speed > 75 && reaction.imageIndex === 0 );
      reaction.rotation += Math.pow( reaction.originalSize, 1.5 ) * 0.005 * ( reverse ? -1 : 1 );
    },
  },
};

export const useCanvasStore = defineStore( "canvas", () => {
  const client = ref<RealtimeChannel>();

  const starCanvas: ShallowRef<{
    pixi: Application | null;
    systems: IStarSystem[];
    structure: StarStructure | null;
  }> = shallowRef( {
    pixi: null,
    systems: [],
    structure: null,
  } );

  const messages = ref<Message[]>( [] );
  const reactions = ref<ReactionInstance[]>( [] );
  const confetti = ref( false );
  const fireworks = ref( false );
  const twinkling = ref( true );
  const shooting = ref( true );

  function createClient () {
    const client = useSupabaseClient();

    return client.channel( "canvas", {
      config: {
        broadcast: { self: true },
      },
    } );
  }

  onMounted( () => {
    client.value = createClient();
    subscribe();
  } );

  type ReactionMessage = {
    payload: {
      items: ReactionInstance[];
    };
  };

  function receiveReactions ( message: ReactionMessage ) {
    const maxItems = Math.min( message.payload.items.length, MAX_REACTIONS );
    const items = message.payload.items.slice( 0, maxItems );
    items.forEach( x => reactions.value.push( x ) );
  }

  function receiveConfetti () {
    confetti.value = true;

    setTimeout( () => {
      confetti.value = false;
    }, 50 );
  }

  function receiveFireworks () {
    fireworks.value = true;

    setTimeout( () => {
      fireworks.value = false;
    }, 50 );
  }

  function subscribe () {
    if ( !client.value ) {
      throw new Error( "Unable to subscribe - missing client" );
    }

    client.value
      .on( "broadcast", { event: "reactions" }, message => receiveReactions( message ) )
      .on( "broadcast", { event: "confetti" }, () => receiveConfetti() )
      .on( "broadcast", { event: "fireworks" }, () => receiveFireworks() )
      .subscribe();
  }

  function addMessage ( message: Message ) {
    const messageState = {
      startX: Math.random(),
      wiggleOffset: Math.random(),
      ...message,
    };

    messages.value.push( messageState );
  }

  function addReaction ( type: string, count = 50 ) {
    if ( !client.value ) {
      throw new Error( "Unable to send reaction - missing client" );
    }

    const items: ReactionInstance[] = [];
    for ( let i = 0; i < count; i += 1 ) {
      const numTypes = reactionConfig[type].colors.length + reactionConfig[type].svgs.length - 1;

      const startX = ( Math.random() * 100 );
      const size = Math.random() * 20 + 20;
      const speed = Math.random() * 50 + 50;
      const imageIndex = Math.floor( Math.random() * numTypes );
      const offsetX = Math.random() * 2 * Math.PI;

      const item = {
        type,
        startX,
        size,
        speed,
        imageIndex,
        rotation: 0,
        originalSize: size,
        offsetX,
      };

      items.push( item );
    }

    client.value.send( {
      type: "broadcast",
      event: "reactions",
      payload: { items },
    } );
  }

  function triggerConfetti () {
    if ( !client.value ) {
      throw new Error( "Unable to send confetti - missing client" );
    }

    client.value.send( {
      type: "broadcast",
      event: "confetti",
    } );
  }

  function triggerFireworks () {
    if ( !client.value ) {
      throw new Error( "Unable to send fireworks - missing client" );
    }

    client.value.send( {
      type: "broadcast",
      event: "fireworks",
    } );
  }

  return {
    messages,
    reactions,
    confetti,
    fireworks,
    twinkling,
    shooting,
    addReaction,
    triggerConfetti,
    triggerFireworks,
    addMessage,
    starCanvas,
  };
} );
